@import "../../../../../../stylesheets/fonts";

.leaderboard__container {
  color: black;
  padding: 10px;
  margin: 1em;
  background-color: white;
  width: 90%;
  max-width: 550px;
  max-height: 300px;
  border: 2px solid #dfdfff;
  border-radius: 8px;
  box-shadow: 0 2px 20px #d9d9d9;

  &__header {
    color: #3b3b7f;
    font-family: $inter;
    font-weight: 600;
    font-size: 20px;
    margin: 10px;
  }

  &__no-leaderboard {
    font-family: $inter;
    display: flex;
    align-items: center;
    height: 220px;
    justify-content: center;
  }
}

.points-table-item {
  color: #2e2e64;
  font-family: $inter;
  font-size: 18px;
  font-weight: 600;
}

.position-table-item {
  color: #2e2e64;
  font-family: $inter;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &--trophy {
    z-index: 0;
    position: relative;
    width: 33px;
    height: 30px;
  }

  &--pos {
    bottom: 5px;
    font-size: 15px;
    position: relative;
    right: 21.5px;
    z-index: 1;
    text-align: center;
  }
}

.name-table-item {
  display: flex;
  flex-direction: column;
  color: #2e2e64;
  font-family: $inter;

  &--name {
    font-weight: 700;
    font-size: 18px;
  }

  &--college {
    font-weight: 300;
    font-size: 12px;
  }
}