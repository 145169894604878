@use "../../../stylesheets/variables";
@use "../../../stylesheets/fonts";
@use "../../../stylesheets/responsiveness.scss";
@import "../../../stylesheets/variables";
@import "../../../stylesheets/fonts";
@import "../../../stylesheets/responsiveness";

.HeroBlurb__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5em;

  &--title {
    font-family: $inter;
    font-weight: 600;
    font-size: 32px;
    text-align: center;

    @media screen and (max-width: $medium) {
      font-size: 28px;
    }
  }

  &--blurb {
    @include fonts.para;
    display: flex;
    text-align: center;
    font-size: 20px;

    @media screen and (max-width: $medium) {
      font-size: 16px;
    }
  }
  margin-bottom: 5em;
}
