@use "../../../../stylesheets/variables";
@use "../../../../stylesheets/fonts";
@use "../../../../stylesheets/responsiveness";
@import "../../../../stylesheets/variables";
@import "../../../../stylesheets/fonts";

.usermgmt__container {
  background-color: #f0f0f0;
  margin-top: 4em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--top {
    display: flex;
    height: 97px;
    width: 100%;

    background-color: #f9f9ff;

    @include responsiveness.screen(smallest, phone) {
      justify-content: center;
      text-align: center;
    }
  }

  &--title {
    padding-top: 30px;
    padding-left: 30px;

    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;

    color: #3b3b7f;

    @include responsiveness.screen(smallest, phone) {
      padding-top: 35px;
      font-size: 30px;
      line-height: 35px;
      padding-left: 0;
    }
  }

  &--charts {
    display: flex;
    margin-top: 31px;
    margin-bottom: 23px;
    width: 90%;
    height: 375px;
    background-color: #fff;
    border-radius: 12px;
  }

  &--top-table {
    width: 90%;
    background-color: #fafbff;
    height: 100px;
  }

  &--search-bar {
    width: 324px;
    height: 34px;
    margin-left: 35px;
    margin-top: 15px;
    outline: none;

    background: #f4f4f4;
    border-radius: 6px;
    border: 0 solid #fff;

    input::placeholder {
      font-family: $nunito-sans;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;

      color: #999898;
    }
    font-family: $nunito-sans;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    color: #000;
  }

  &--table {
    display: flex;
    width: 90%;
  }
}
