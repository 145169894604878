@import "../../stylesheets/variables";
@import "../../stylesheets//fonts";

.logout__button {
  padding: 0.5em 2em;
  border: none;
  border-radius: 6px;
  font-family: $nunito;
  color: $dark-white;
  cursor: pointer;

  .app--dark & {
    background-color: $dark-light-purple;
  }

  .app--light & {
    background-color: $light-orange;
  }

  .app--portal & {
    background-color: $portal-light-purple;
  }
}