@use "../../stylesheets/fonts";

.loading__container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  @include fonts.para;
}