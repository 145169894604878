@import "../../../stylesheets/fonts";

.schedule {
  font-family: $inter;
  font-size: 34px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;

  &__title {
    margin-bottom: 0.75em;
  }
}

.schedule-tabs {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &__tab {
    @mixin tab {
      all: unset;
      border-radius: 8px 8px 0 0;
      font-size: 22px;
      height: 40px;

      @media screen and (max-width: 400px) {
        font-size: 16px;
      }

      &:hover {
        cursor: pointer;
      }

      &.selected {
        background-color: #dcdcdc;
        filter: drop-shadow(0 10px 20px #cdcdcd);
        color: #32244d;
      }
    }

    @include tab;
    background-color: #32244d;

    &--light {
      @include tab;
      background-color: #d45c3e;
    }
  }
}

.schedule-page {
  background-color: #dcdcdc;
  color: #291d40;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  border-radius: 0 0 8px 8px;
  filter: drop-shadow(0 10px 10px #cdcdcd);
  font-size: 20px;
  font-family: $nunito;
  width: 90%;

  &__title {

    border-bottom: 1px solid #534868;
    padding-bottom: 3px;
    width: 85%;
    text-align: left;
    font-style: italic;
  }

  &__event {
    width: 85%;
    display: inline-flex;
    margin: 10px 0;

    &--time {
      margin-left: auto;
    }
  }
}