@import "../../../../stylesheets/fonts";

.teamformation {
  padding-top: 100px;
  background-color: #f5f5f5;
  min-height: 100vh;

  &__header {
    color: #3b3b7f;
    font-family: $inter;
    font-size: 36px;
    font-weight: 600;
    text-align: left;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--col {
      margin: 0 30px;
      width: 90%;
      max-width: 550px;
    }

    &--row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}