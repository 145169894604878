.message {
  @keyframes slide {
    0% {
      transform: translateX(110vw);
    }

    10%,
    80% {
      transform: translateX(0);
    }

    90%,
    100% {
      transform: translateX(-110vw);
    }
  }

  @keyframes move-text {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-50%);
    }
  }

  @mixin message {
    height: 35px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    margin-top: 4em;
    animation: 5s ease 0s slide;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow-x: none;

    &--text {
      margin-left: 30px;
      font-size: 20px;

      &.scroll {
        animation: 10s ease 0s move-text;
      }
    }
  }

  &--ERROR {
    @include message;
    background-color: #e55039;
  }

  &--SUCCESS {
    @include message;
    background-color: #7dd076;
  }
}