// credit: https://cssloaders.github.io/

.spinner__container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  color: #6f6fe8;
  position: relative;
  font-size: 11px;
  background: #6f6fe8;
  animation: escale-y 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
  animation-delay: -0.16s;
}

.spinner::before,
.spinner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 2em;
  background: #6f6fe8;
  width: 1em;
  height: 4em;
  animation: escale-y 1s infinite ease-in-out;
}

.spinner::before {
  left: -2em;
  animation-delay: -0.32s;
}

@keyframes escale-y {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}