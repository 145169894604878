// update when color theme available

// dark theme colors
$dark-dark-blue: #081428;
$dark-navy-blue: #142132;
$dark-violet: #6f6fe8;
$dark-dark-purple: #1b1b37;
$dark-purple: #2a1d3e;
$dark-white: #fdfffa;
$dark-gray: #cfd1d4;
$dark-green: #7dcfb6;
$dark-faded-blue: #5b85aa;
$dark-light-blue: #4ba5d7;
$dark-light-purple: #6f6fe8;

// light theme colors
$light-dark-blue: #194263;
$light-green: #649185;
$light-faded-blue: #395477;
$light-light-orange: #d68c74;
$light-orange: #d45c3e;
$light-blue: #3d8dba;
$light-light-blue: #4ba5d7;
$light-lighter-blue: #a0dafa;

// portal theme colors
$portal-dark-blue: #1b1b37;
$portal-light-purple: #6f6fe8;

@mixin dark-background {
  background: linear-gradient(
    180deg,
    #081428 22.92%,
    #13243c 52.08%,
    #243d5e 100%
  );

  z-index: 1;
}

@mixin dark-about-background {
  background: linear-gradient(
    180deg,
    rgba(57 39 84 / 70%) 0%,
    rgba(42 29 62 / 0%) 100%
  );
  transition: background 5s ease;
  transition: background-color 5s ease;
}

@mixin light-background {
  background: linear-gradient(
    180deg,
    #224e71 0%,
    #598d91 35%,
    #83a993 45%,
    #edb760 100%
  );
}

@mixin livesite-background {
  background: linear-gradient(
    180deg,
    #1b1b37 0%,
    #3b3b80 100%
  );
}
