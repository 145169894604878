@import "https://fonts.googleapis.com/css?family=Nunito|Monsterrat|Inter:300,400,500,600,700,800|Barlow|Nunito+Sans";

$nunito-sans: "Nunito Sans", sans-serif;
$nunito: "Nunito", sans-serif;
$barlow: "Barlow", sans-serif;
$inter: "Inter", sans-serif;

// update when fonts are selected
@mixin navbar {
  font-family: $nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}

// Front page Cruzhacks 2023
@mixin h1 {
  font-family: $barlow;
  font-style: normal;
  font-weight: 700;
  font-size: 90px;
  line-height: 110px;
}

// About Us
@mixin h2 {
  font-family: $barlow;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
}

@mixin subheader {
  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
}

@mixin para {
  font-family: $nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

@mixin title {
  font-family: $barlow;
  font-weight: 500;
  font-size: 90px;
  color: white;
}
