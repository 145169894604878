@import "../../../../../../stylesheets/fonts";

.checklist__container {
  background-color: white;
  padding: 20px 30px;
  width: 80%;
  max-width: 500px;
  border-radius: 12px;
  box-shadow: 0 2px 20px rgb(205 205 205 / 50%);
  margin: 1em;
  font-family: $inter;
  color: #3b3b7f;

  @media screen and (max-width: 400px) {
    margin: 0.5em;
    padding: 20px;
  }

  &__header {
    font-weight: 700;
    font-size: 24px;
  }

  &__message {
    font-size: 14px;
    margin: 1em 100px 1em 24px;
    line-height: 1.5;

    @media screen and (max-width: 400px) {
      margin-right: 24px;
    }
  }

  @mixin checklist-button {
    all: unset;
    background-color: #6f6fe8;
    margin-left: 24px;
    border-radius: 6px;
    text-align: center;
    color: white;
    padding: 5px 30px;
    transition: padding ease 0.25s;
  }

  &__button {
    @include checklist-button;

    &:hover {
      cursor: pointer;
      padding: 5px 40px;
      background-color: #6f6fe8;
    }

    &--greyedOut {
      @include checklist-button;
      background-color: #d4d4d4;
      color: #525252;

      &:hover {
        background-color: #d4d4d4;
      }
    }
  }
}