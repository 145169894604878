.announcement {
  display: flex;
  flex-direction: column;
  padding: 0;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
}

.announcement-title {
  flex-basis: 100%;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.announcement-sep {
  border-top: 1px solid #6f6fe8;
  min-width: 100%;
  height: 1px;
  display: block;
  margin-top: 0.1em;
  margin-bottom: 0.5em;
}

.announcement-body {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}