@import "../../../../../../stylesheets/fonts";

.submission__container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 20px #d9d9d9;
  color: #3b3b7f;
  font-family: $inter;
  width: 100%;
  max-width: 550px;
  margin: 1em;

  &__title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 1em;
  }

  &__message {
    font-weight: 400;
    margin-bottom: 1em;
  }

  &__submit-button {
    @mixin submit-button {
      all: unset;
      border-radius: 6px;
      width: 120px;
      padding: 5px 10px;
      text-align: center;
    }

    &--false {
      @include submit-button;
      background-color: #d4d4d4;
      color: #525252;
    }

    &--true {
      @include submit-button;
      background-color: #8c8cff;
      color: white;

      &:hover {
        cursor: pointer;
        width: 135px;
      }
    }
  }
}
