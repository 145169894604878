@import "../../../../../../stylesheets/fonts";
@import "../../../../../../stylesheets/variables";

.attendance {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 550px;
  padding: 10px;
  margin: 1rem;
  transition: all ease 1.5s;
  position: relative;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: left;
    color: #1b1b37;
    font-family: $inter;
    font-weight: bold;
    justify-content: center;
    font-size: 16px;

    @media screen and (max-width: 600px) {
      font-size: 12px;
      padding: 10px;
    }

    %status {
      display: inline-block;
      border-radius: 6px;
      padding: 8px;
      margin: 5px;
    }

    &__checkin {
      display: flex;
      justify-content: center;
      border-radius: 12px;

      &--button {
        all: unset;
        height: 40px;
        width: calc(100%);
        font-weight: 500;
        background-color: #8c8cff;
        border: #8c8cff solid 2px;
        box-shadow: 0 2px 20px rgb(205 205 205 / 50%);
        border-radius: 12px;
        text-align: center;
        color: white;
        transition: padding 0.25s ease;

        &:hover {
          cursor: pointer;
        }

        &:active {
          background-color: transparent;
          color: #8c8cff;
        }
      }
    }

    &__application {
      margin: 1em 0;

      &--text {
        display: inline;
        margin-right: 1em;
      }

      &__status {
        @extend %status;
        background-color: #7dd076;
        color: white;
      }
    }

    &__attendance {
      &--text {
        display: inline;
        margin-right: 1em;
      }

      &__status {
        &--NOT-CONFIRMED {
          @extend %status;
          background-color: #d4d4d4;
        }

        &--NOT-ATTENDING {
          @extend %status;
          background-color: #e55039;
          color: white;
        }

        &--CONFIRMED {
          @extend %status;
          background-color: #7dd076;
          color: white;
        }
      }

      &--confirm-button {
        all: unset;
        display: inline-block;
        margin: 5px;
        padding: 8px;
        border-radius: 6px;
        border: 1px solid #6f6fe8;
        color: #6f6fe8;
        width: 5em;
        text-align: center;
        transition:
          padding ease 0.25s,
          margin ease 0.25s;

        &:hover {
          background-color: #6f6fe8;
          color: white;
          cursor: pointer;
          padding-top: 11.5px;
          padding-bottom: 11.5px;
          margin: 1.5px 5px;
        }
      }

      &__withdraw {
        margin-top: 10px;
        white-space: nowrap;
        font-weight: 400;

        &--text {
          color: #737373;
          display: inline-block;
          margin-right: 5px;
        }

        &--button {
          all: unset;
          font-family: $inter;
          text-decoration: underline;
          color: #1f1f46;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.qr-code {
  &__text {
    font-family: $inter;
    font-size: 24px;
    text-align: center;
  }
}