@import "../../stylesheets/variables";
@import "../../stylesheets/fonts";

.nav {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $nunito;

  top: 0;
  height: 4em;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 11px #000;

  .app--dark & {
    background-color: $dark-dark-purple;
  }

  .app--light & {
    background-color: $light-faded-blue;
  }

  .app--portal & {
    background-color: $portal-dark-blue;
    box-shadow: none;
  }
}

.nav__container {
  position: relative;
  min-width: 100%;
  max-width: 1000px;

  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: $dark-white;
  }

  &--left {
    flex-grow: 2;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;

    margin-left: 2em;
  }

  &--right {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    max-width: 750px;
    margin-right: 1em;

    &--item {
      flex-grow: 1;
      font-size: 0.85em;
      text-align: center;
      position: relative;
    }

    &--item__link {
      position: relative;

      // Link Underline
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 120%;
        width: 100%;
        height: 3px;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
        transform-origin: 0 0;
      }

      // Underline Color
      .app--light &::after {
        background-color: $light-orange;
      }

      .app--dark &::after {
        background-color: $dark-green;
      }

      .app--portal &::after {
        background-color: $portal-light-purple;
      }

      // Underline Transition
      .active &::after {
        transform: scale3d(1, 1, 1);
      }
    }

    // MLH Logo
    img {
      height: 2em;
    }
  }

  // padding-right: 10%;
}

@media only screen and (max-width: 850px) {
  .nav {
    align-items: flex-start;

    height: 4em; // height: 4em;
    width: 100vw;
    box-shadow: none;

    .app--dark & {
      background-color: transparent;
    }

    .app--light & {
      background-color: transparent;
    }

    .app--portal & {
      background-color: transparent;
    }
  }

  .nav__container {
    padding-bottom: 1em;
    align-items: flex-start;
    width: 100vw;

    // border: 1px solid red;

    .app--dark & {
      background-color: $dark-dark-blue;
    }

    .app--light & {
      background-color: $light-faded-blue;
    }

    .app--portal & {
      background-color: $portal-dark-blue;
    }

    &--left {
      position: absolute;
      margin: 0;
      padding: 1em;
      width: 100vw;
      z-index: 10;

      font-size: 0.8em;

      .app--dark & {
        background-color: $dark-dark-blue;
      }

      .app--light & {
        background-color: $light-faded-blue;
      }

      .app--portal & {
        background-color: $portal-dark-blue;
      }

      .logo {
        height: 4em;
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 1.5em;
      margin-left: auto;

      &__dropdown {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 1.5em;
        margin-top: 3em;
        transition: margin-top 300ms ease;

        &.hidden {
          margin-top: -10em;
          z-index: -1;
        }
      }

      img {
        display: none;
      }
    }
  }
}

.badge_container {
  position: relative;
  width: 80px;
  height: 65px;
  margin-right: 50px;
  z-index: 10;

  @media only screen and (max-width: 850px) {
    position: absolute;
    top: 81.78px;
    right: 10px;
    margin-right: 0;
  }
}

.mlh_badge {
  position: absolute;
  top: 0;
  right: 10px;
  width: 80px;

  &:hover {
    cursor: pointer;
  }
}

.logo-title {
  font-family: $barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: white;
  margin-left: -0.75em;

  @media screen and (max-width: 400px) {
    display: none;
  }
}

.portal-full-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}