/* stars animation: https://www.youtube.com/watch?v=pdNJQzPqLns */
.shooter-Wrapper {
  position: relative;
  z-index: -1000;
  overflow: visible;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: right;
  gap: 30vw;
}

.shooter-Container {
  position: relative;
  width: 200px;
  height: 500px;
  transform: rotate(145deg);
}

.shooter {
  position: absolute;
  height: 2px;
  background: linear-gradient(-45deg, #7dcfb6 2.17%, rgb(125 207 182 / 0%) 122.05%);
  filter: drop-shadow(0 0 6px #7dcfb6);
  animation: tail 7s ease-in-out infinite, shooting 7s ease-in-out infinite;
}

.shooter::before,
.shooter::after {
  opacity: 0;
  position: absolute;
  content: "";
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  width: 30px;
  background: linear-gradient(-45deg, rgb(0 0 255 / 0%), #7dcfb6, rgb(0 0 255 / 0%));
  border-radius: 100%;
  transform: translateX(50%) rotateZ(45deg);
  animation: shining 7s ease-in-out infinite;
}

.shooter::after {
  transform: translateX(50%) rotateZ(-45deg);
}

.shooter:nth-child(1) {
  top: 0;
  left: 0;
  animation-delay: 650ms;
}

.shooter:nth-child(1)::before,
.shooter:nth-child(1)::after {
  animation-delay: 650ms;
}

.shooter:nth-child(2) {
  top: calc(50% - -50px);
  left: calc(50% - 190px);
  animation-delay: 150ms;
}

.shooter:nth-child(2)::before,
.shooter:nth-child(2)::after {
  animation-delay: 150ms;
}

.shooter:nth-child(3) {
  top: calc(50% - -90px);
  left: calc(50% - 200px);
  animation-delay: 1600ms;
}

.shooterar:nth-child(3)::before,
.shooter:nth-child(3)::after {
  animation-delay: 1600ms;
}

.shooter:nth-child(4) {
  top: calc(50% - 50px);
  left: calc(50% - 250px);
  animation-delay: 4700ms;
}

.shooter:nth-child(4)::before,
.shooter:nth-child(4)::after {
  animation-delay: 4700ms;
}

@keyframes tail {
  0% {
    width: 0;
  }

  30% {
    width: 100px;
  }

  100% {
    width: 0;
  }
}

@keyframes shining {
  0% {
    opacity: 1;
    width: 0;
  }

  50% {
    opacity: 1;
    width: 30px;
  }

  100% {
    opacity: 1;
    width: 0;
  }
}

@keyframes shooting {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(80vh);
  }
}

.mobile {
  @media screen and (max-width: 500px) {
    display: none;
  }
}