@use "../../../../stylesheets/variables";
@use "../../../../stylesheets/fonts";
@use "../../../../stylesheets/responsiveness";
@import "../../../../stylesheets/variables";
@import "../../../../stylesheets/fonts";

.overview__container {
  background-color: #f0f0f0;
  margin-top: 4em;

  &--title {
    display: flex;
    height: 97px;
    width: 100%;

    background-color: #f9f9ff;

    @include responsiveness.screen(smallest, phone) {
      justify-content: center;
      text-align: center;
    }
  }

  &--text {
    padding-top: 30px;
    padding-left: 30px;

    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;

    color: #3b3b7f;

    @include responsiveness.screen(smallest, phone) {
      padding-top: 35px;
      font-size: 30px;
      line-height: 35px;
      padding-left: 0;
    }
  }

  /*
  &--title {
    width: 100%;
    height: 97px;

    background: #f9f9ff;
  }

  &--text {
    padding-top: 30px;
    padding-left: 30px;

    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;

    color: #3b3b7f;
  }
  */
}
