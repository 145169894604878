@import "../../../../stylesheets/fonts";

.maindash {
  padding-top: 100px;
  background-color: #f5f5f5;
  min-height: 100vh;
  width: 100vw;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;

    &--row {
      width: 90%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }

    &--column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 550px;
      align-items: center;
      padding: 0 10px;
      margin: 1em;
    }
  }

  &__lower-container {
    display: flex;
    justify-content: center;
    flex-flow: row wrap-reverse;
  }
}

.checklist {
  font-family: $inter;
  color: #3b3b7f; // Change to #EEEEEE
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  width: 100%;
  max-width: 550px;

  &__title {
    font-weight: 700;
    font-size: 36px;
    width: 90%;
    max-width: 550px;
    margin: 1rem;
    padding: 0 10px;

    &__blurb {
      font-size: 18px;
      font-weight: 400;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.important-dates {
  width: 90%;
  max-width: 550px;
  padding: 10px;
  color: #54548d;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }

  &__table {
    background-color: white;
    width: 90%;
    max-width: 550px;
    padding: 30px 10px;
    box-shadow: 0 2px 20px rgb(205 205 205 / 50%);
    border-radius: 12px;
    margin-top: 3.5em;
    height: 200px;
  }
}
