@use "../../../../../../stylesheets/variables";
@use "../../../../../../stylesheets/fonts";
@use "../../../../../../stylesheets/responsiveness";
@import "../../../../../../stylesheets/variables";
@import "../../../../../../stylesheets/fonts";

.hacker_table__container {
  margin-bottom: 40px;
}

.status__container-accept {
  // margin-right: 0;
  // margin-left: auto;
  width: 121px;
  height: 26px;
  background: #edffec;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $nunito-sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.035em;

  color: #000;
}

.status__container-reject {
  // margin-right: 0;
  // margin-left: auto;
  width: 121px;
  height: 26px;
  background: #ffe7e7;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $nunito-sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.035em;
}

.status__container-not_applicable {
  // margin-right: 0;
  // margin-left: auto;
  width: 121px;
  height: 26px;
  background: #e4e4e4;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $nunito-sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.035em;

  color: #000;
}

.check_in__container {
  display: flex;
  flex-direction: row;

  // margin-right: 0;
  // margin-left: auto;
  // align-items: right;
  // justify-content: right;

  &--text {
    margin-right: 5px;
    font-family: $nunito-sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    color: #000;
  }
}

.table-spinner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 150px;
}
