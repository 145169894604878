@use "../../stylesheets/variables";

.home__container {
  display: flex;
  flex-direction: column;

  max-width: 1000px;
  margin: 0 auto;
  padding: 4em 2em;

  scroll-behavior: contain;
}
