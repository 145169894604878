@use "../../../../stylesheets/variables";
@use "../../../../stylesheets/fonts";
@use "../../../../stylesheets/responsiveness";
@import "../../../../stylesheets/variables";
@import "../../../../stylesheets/fonts";

.qr__container {
  background-color: #f0f0f0;
  margin-top: 4em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--title {
    display: flex;
    height: 97px;
    width: 100%;

    background-color: #f9f9ff;

    @include responsiveness.screen(smallest, phone) {
      justify-content: center;
      text-align: center;
    }
  }

  &--text {
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 20px;

    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;

    color: #3b3b7f;

    @include responsiveness.screen(smallest, phone) {
      padding-top: 35px;
      font-size: 30px;
      line-height: 35px;
      padding-left: 0;
    }
  }

  &--box {
    margin-top: 3em;
    margin-bottom: 3em;
    background-color: #fff;
    width: 70%;
    height: 90%;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--camera {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.checkin-modal__container {
  &--title {
    margin-top: 2em;
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 23px;

    text-align: center;

    color: #3b3b7f;

    @include responsiveness.screen(smallest, phone) {
      font-size: 20px;
      line-height: 23px;
    }
  }

  &--email {
    margin-top: 0.4em;
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;

    text-align: center;

    color: #000;

    @include responsiveness.screen(smallest, phone) {
      font-size: 13px;
      line-height: 17px;
    }
  }

  &--buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2em;

    &--checkIn {
      width: 150px;
      height: 40px;
      margin-left: 20px;

      font-family: $nunito-sans;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;

      text-align: center;

      border-radius: 6px;
      border: 1px solid #5cb03e;

      color: #5cb03e;

      &:hover {
        color: #fff;
        background-color: #5cb03e;
      }

      @include responsiveness.screen(smallest, phone) {
        font-size: 20px;
        width: 125px;

        margin-left: 10px;
      }
    }

    &--cancel {
      width: 150px;
      height: 40px;
      margin-right: 20px;

      font-family: $nunito-sans;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;

      text-align: center;

      border-radius: 6px;
      border: 1px solid #a31919;

      color: #a31919;

      &:hover {
        color: #fff;
        background-color: #a31919;
      }

      @include responsiveness.screen(smallest, phone) {
        font-size: 20px;
        width: 125px;

        margin-right: 10px;
      }
    }
  }
}
