@import "../../stylesheets/variables";
@import "../../stylesheets/fonts";

.login__button {
  font-family: $nunito;
  padding: 0.5em 2em;
  border: none;
  border-radius: 6px;

  color: $dark-white;

  &:hover {
    cursor: pointer;
  }

  .app--dark & {
    background-color: $dark-light-purple;
  }

  .app--light & {
    background-color: $light-orange;
  }

  .app--portal & {
    background-color: $portal-light-purple;
  }
}