@import "../../stylesheets/responsiveness";

.social_button {
  all: unset;

  &:hover {
    cursor: pointer;
    fill: white;
  }

  &--svg {
    height: 52px;
    width: 52px;
    margin: 0 20px;

    transition: all ease 0.1s;

    &:hover {
      height: 55px;
      width: 55px;
      margin: -1.5px 18.5px;
      filter: drop-shadow(1px 1px 2px rgb(255 255 255 / 70%));
    }

    @media screen and (max-width: $large-medium) {
      height: 38px;
      width: 38px;
    }

    @media screen and (max-width: $medium) {
      height: 33px;
      width: 33px;
    }

    @media screen and (max-width: 500px) {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}