@use "stylesheets/variables";
@use "stylesheets/responsiveness";
@import "stylesheets/variables";

@mixin appthemesizing {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

html,
body,
#root,
#root > div {
  position: relative;
  margin: 0;
  padding: 0;
  overscroll-behavior: contain;
  height: 100vh;
  overflow-x: clip;
}

.app {
  font-family: Arial, Helvetica, sans-serif;
  color: $dark-white;
  display: flex;
  flex-direction: column;

  .app--light {
    @include appthemesizing;
    @include variables.light-background;
  }

  .app--dark {
    @include appthemesizing;
    @include variables.dark-background;
  }

  .app--portal {
    @include appthemesizing;
  }
}

.fg {
  position: relative;
  z-index: 2;
}
